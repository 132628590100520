/***** Homepage *****/

/*** Intro ***/
.section-intro {
  background: url("../images/intro.jpg") 50% 50% no-repeat;
  background-size: cover;
  height: 90vh;
  position: relative;
  @include max($lg) {
    height: 70vh;
  }
  .intro-text {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    background: rgba(#fff, 0.3);
    font-family: $font-heading;
    font-size: 1.67vw;
    text-align: center;
    width: 23vw;
    padding: 2vw 3vw;
    @include max($xxl) {
      width: 33vw;
      font-size: 2vw;
    }
    @include max($lg) {
      width: 300px;
      padding: 20px;
      left: 50%;
      background: rgba(#fff, 0.7);
      font-size: 20px;
    }
    .text-uppercase {
      font-size: 3.33vw;
      line-height: 1.1;
      @include max($xxl) {
        font-size: 4vw;
      }
      @include max($lg) {
        font-size: 30px;
      }
    }
  }
}

/*** Info ***/
.section-info {
  padding: 130px 0;
  @include max($lg) {
    padding: 60px 0 50px;
  }
  .info-row {
    display: flex;
    align-items: stretch;
    @include max($lg) {
      display: block;
    }
    .info-col {
      width: 50%;
      flex: 0 0 50%;
      font-size: 18px;
      @include max($lg) {
        width: 100%;
      }
      @include max($sm) {
        font-size: 16px;
      }
      @include max($xxxs) {
        font-size: 14px;
      }
      .list-arrow {
        li {
          background-position: 0 6px;
          @include max($sm) {
            background-position: 0 4px;
          }
          @include max($xxxs) {
            background-position: 0 2px;
          }
        }
      }
      &.info-col-l {
        padding-right: 70px;
        position: relative;
        @include max($lg) {
          padding: 0 0 30px;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          top: 10px;
          bottom: 30px;
          width: 1px;
          background: #000;
          @include max($lg) {
            left: 0;
            top: auto;
            bottom: 0;
            width: 100%;
            height: 1px;
          }
        }
      }
      &.info-col-r {
        padding-left: 70px;
        @include max($lg) {
          padding: 40px 0 0;
        }
      }
    }
  }
}

/*** Services ***/
.section-services {
  background: #f5f5f5;
  padding: 130px 0 110px;
  @include max($lg) {
    padding: 60px 0;
  }
  h2 {
    text-transform: uppercase;
    margin-bottom: 16px;
  }
  .services-steps {
    max-width: 920px;
    margin: 0 auto;
    .step {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      @include max($lg) {
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 12px;
        height: 12px;
        background: #000;
        border-radius: 50%;
        z-index: 1;
        @include max($sm) {
          left: 80px;
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        top: 0;
        bottom: 0;
        background: #ddd;
        z-index: 0;
        @include max($sm) {
          left: 80px;
        }
      }
      &:first-child {
        &:after {
          top: 50%;
        }
      }
      &:last-child {
        &:after {
          bottom: 50%;
        }
      }
      .step-img {
        line-height: 0;
        width: 50%;
        flex: 0 0 50%;
        @include max($sm) {
          width: 80px;
          flex: 0 0 80px;
          order: 1;
        }
        img {
          border-radius: 50%;
        }
      }
      .step-info {
        width: 50%;
        flex: 0 0 50%;
        @include max($sm) {
          width: auto;
          flex: 1;
          order: 2;
        }
      }
      .step-title {
        font-size: 24px;
        font-family: $font-heading;
        text-transform: uppercase;
        line-height: 1.2;
        margin-bottom: 7px;
        @include max($sm) {
          font-size: 20px;
        }
      }
      .step-desc {
      }
      &.step-l {
        .step-img {
          padding-left: 50px;
          @include max($lg) {
            padding-left: 30px;
          }
          @include max($sm) {
            padding: 0 20px 0 0;
          }
        }
        .step-info {
          padding-right: 50px;
          text-align: right;
          @include max($lg) {
            padding-right: 30px;
          }
          @include max($sm) {
            text-align: left;
            padding: 0 0 0 20px;
          }
        }
      }
      &.step-r {
        .step-img {
          text-align: right;
          padding-right: 50px;
          @include max($lg) {
            padding-right: 30px;
          }
          @include max($sm) {
            padding: 0 20px 0 0;
          }
        }
        .step-info {
          padding-left: 50px;
          @include max($lg) {
            padding-left: 30px;
          }
          @include max($sm) {
            padding: 0 0 0 20px;
          }
        }
      }
    }
  }
}

/*** Gallery ***/
.section-gallery {
  background: #f5f5f5;
  padding: 0 0 130px;
  @include max(1440px) {
    padding: 0 0 10px;
  }
  @include max($lg) {
    padding: 0 0 10px;
  }
  .container {
    @include max(1440px) {
      padding: 0;
    }
  }
  .beer-slider {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .gallery-w {
    position: relative;
  }

  .main-slider-w {
    // position: relative;
    margin-bottom: 10px;
    .swiper-slide {
      line-height: 0;
    }
    // .swiper-button-next,
    // .swiper-button-prev {
    //   bottom: 70px; // 184/2 - 22
    //   top: auto;
    //   color: #000;
    //   @include max(1440px) {

    //   }
    //   &:after {
    //     @include max($sm) {
    //       font-size: 30px;
    //     }
    //   }
    // }
    // .swiper-button-prev {
    //   left: -50px;

    //   @include max(1440px) {
    //     left: 10px;
    //   }
    // }
    // .swiper-button-next {
    //   right: -50px;
    //   @include max(1440px) {
    //     right: 10px;
    //   }
    // }
  }
  .thumbs-slider-w {
    position: relative;
    @include max(1440px) {
      padding: 0 50px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      color: #000;
      &:after {
        font-size: 30px;
        @include max($sm) {
          font-size: 20px;
        }
      }
    }
    .swiper-button-prev {
      left: -50px;
      @include max(1440px) {
        left: 10px;
      }
    }
    .swiper-button-next {
      right: -50px;
      @include max(1440px) {
        right: 10px;
      }
    }
  }
  .thumbs-slider {
    .swiper-slide {
      border: 1px solid transparent;
      line-height: 0;
      img {
        transition: all 0.2s;
      }
      &.swiper-slide-thumb-active {
        border-color: #000;
        img {
          opacity: 0.7;
        }
      }
    }
  }
}
.beer-reveal {
  border-right: 2px solid #fff;
}

/*** Highlight ***/
.section-highlight {
  margin: 0 auto;

  max-width: 920px;
  .highlight-block {
    border: 1px solid #000;
    padding: 50px;
    font-size: 24px;
    font-family: $font-heading;
    text-align: center;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &#sectionHighlight1 {
    padding: 130px 0 0;
    @include max($lg) {
      padding: 60px 0 0;
    }
  }
  &#sectionHighlight2 {
    padding: 130px 0;
    @include max($lg) {
      padding: 60px 0;
    }
  }
}

/*** Pricing ***/
.section-pricing {
  padding: 130px 0 0;
  @include max($lg) {
    padding: 60px 0 0;
  }
  .container {
    max-width: 1200px;
  }
  h2 {
    text-transform: uppercase;
  }
  .pricing-list {
    margin: 0 -10px;
    display: flex;
    align-items: stretch;
    @include max($lg) {
      margin: 0;
      display: block;
    }
    .pricing-item {
      padding: 0 10px;
      width: 33.3333%;
      flex: 0 0 33.3333%;
      display: flex;
      align-items: stretch;
      @include max($lg) {
        width: 100%;
        padding: 0;
        display: block;
      }
      &:not(:last-child) {
        @include max($lg) {
          margin-bottom: 10px;
        }
      }
    }
  }
  .card.card-pricing {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    .card-body {
      padding: 20px 30px 10px;
    }
    .card-number {
      font-family: $font-heading;
      font-size: 72px;
      line-height: 1;
      margin-bottom: 10px;
    }
    .card-title {
      font-family: $font-heading;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 15px;
    }
    .card-text {
      text-align: left;
      p {
      }
      ul {
        li {
        }
      }
    }
    .card-footer {
      margin-top: auto;
      padding: 20px;
      background: rgba(#000, 0.08);
    }
    .card-price {
      font-size: 36px;
      font-weight: bold;
      font-family: $font-heading;
    }
    &.card-pricing-1 {
      background: #f5f5f5;
      .card-number {
        color: #aaa;
      }
      .card-price {
        color: #666;
      }
    }
    &.card-pricing-2 {
      background: #444;
      .card-body {
        color: #fff;
      }
      .card-number {
        color: #999;
      }
      .card-price {
        color: #fff;
      }
    }
    &.card-pricing-3 {
      background: #bbb;
      .card-number {
        color: #666;
      }
      .card-price {
        color: #666;
      }
    }
  }
}

/*** Results ***/
.section-results {
  padding: 130px 0 0;
  @include max($lg) {
    padding: 60px 0 0;
  }
  .container {
    max-width: 920px;
  }
  h2 {
    text-transform: uppercase;
  }
  .results-list {
    .results-item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .card.card-result {
    background: #f5f5f5;
    text-align: center;
    padding: 20px 30px;
    .card-address {
      font-size: 14px;
      margin-bottom: 18px;
    }
    .card-sold {
      font-weight: bold;
      margin-bottom: 15px;
      font-family: $font-heading;
    }
    .card-quote {
      font-size: 24px;
      font-family: $font-heading;
      font-style: italic;
    }
    .card-benefit {
      font-size: 24px;
      font-family: $font-heading;
    }
  }
}

/*** FAQ ***/
.section-faq {
  padding: 130px 0 0;
  font-family: $font-heading;
  @include max($lg) {
    padding: 60px 0 0;
  }
  .faq-list {
    .faq-item {
      &:not(:last-child) {
        border-bottom: 1px solid #f5f5f5;
      }
      .question {
        padding: 15px 0;
        display: flex;
        align-items: flex-start;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        .text {
          padding-right: 10px;
        }
        .icon {
          margin: 4px 0 0 auto;
          transition: all 0.2s;
        }
      }
      .answer {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s;
        padding: 0 30px;
        background: #f5f5f5;
        margin: 0 0 0 60px;
        font-size: 14px;
        @include max($lg) {
          margin: 0;
        }
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.expanded {
        .question {
          .icon {
            transform: rotate(180deg);
          }
        }
        .answer {
          padding: 20px 30px;
          max-height: 400px;
          opacity: 1;
          visibility: visible;
          margin: 0 0 30px 60px;
          @include max($lg) {
            margin: 0 0 30px 0;
          }
        }
      }
    }
  }
}
