/***** Header *****/

.header {
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.5s;
  .header-i {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    display: flex;
    align-items: center;
    @include max($lg) {
      z-index: 2;
      position: relative;
    }
    img {
      width: 206px;
      height: 42px;
    }
    span {
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 22px;
      letter-spacing: -0.04em;
      @include max($xxxs) {
        font-size: 18px;
      }
    }
  }
  .header-r {
    display: flex;
    align-items: center;
    margin: 0 -20px 0 auto;
    @include max($lg) {
      margin: 0 -10px 0 auto;
    }
    .topmenu {
      position: relative;
      font-family: $font-heading;
      transition: all 0.3s;
      @include max($lg) {
        display: none;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.95);
        justify-content: center;
        align-items: center;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        @include max($lg) {
          display: block;
        }
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          display: block;
          a {
            display: block;
            padding: 10px 20px;
            @include max($lg) {
              font-size: 18px;
            }
          }
        }
      }
      &.expanded {
        display: flex;
      }
    }
    .toggler {
      padding: 10px;
      height: 42px;
      display: none;
      justify-content: center;
      align-items: center;
      line-height: 0;
      cursor: pointer;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0);
      transition: all 0.3s;
      z-index: 2;
      position: relative;
      @include max($lg) {
        display: flex;
      }
      .icon {
        width: 22px;
        transition: all 0.3s;
        &.icon-close {
          display: none;
        }
      }
      &:hover {
        .icon {
          fill: $grey6;
        }
      }
      &.active {
        @include max($lg) {
          // background: rgba(0, 0, 0, .1);
          .icon {
            &.icon-menu {
              display: none;
            }
            &.icon-close {
              display: block;
            }
          }
        }
      }
    }
  }
}

body.scrolled {
  .header {
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 0;
  }
}
