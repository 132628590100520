/***** Simple elements, atoms *****/

.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}

.h-underline {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 1em;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70px;
    height: 1px;
    background: #000;
  }
  &.text-center {
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.list-arrow {
  padding: 0;
  margin: 1em 0;
  li {
    position: relative;
    padding-left: 30px;
    display: block;
    background: url("../images/icons/arrow.svg") 0 4px no-repeat;
    background-size: 16px 16px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
