/*** Contact ***/
.section-contact {
  background: #eee;
  font-family: $font-heading;
  padding: 130px 0;
  @include max($lg) {
    padding: 60px 0;
  }
  h2 {
    text-transform: uppercase;
  }
  .contact-row {
    display: flex;
    margin: 40px -10px 0;
    @include max($lg) {
      margin: 0 auto;
      max-width: 320px;
      display: block;
    }
    .contact-col {
      width: 33.3333%;
      flex: 0 0 33.3333%;
      @include max($lg) {
        width: 100%;
      }
      &.contact-col-1 {
        padding-right: 40px;
        @include max($lg) {
          padding: 0 0 30px;
        }
        p {
          max-width: 300px;
        }
      }
      &.contact-col-2 {
        padding-right: 40px;
        @include max($lg) {
          padding: 0 0 30px;
        }
      }
      &.contact-col-3 {
        text-align: right;
        @include max($lg) {
          text-align: left;
        }
      }
    }
  }
  .contact-item {
    display: flex;
    align-items: flex-start;
    .icon {
      margin-right: 20px;
      width: 16px;
      flex: 0 0 16px;
      &.icon-mobile {
        width: 11px;
        height: 19px;
        margin-top: 2px;
      }
    }
    span {
      a {
      }
    }
    &.contact-phone {
      .icon.icon-mobile {
        margin: 8px 20px 0 0;
        width: 11px;
        height: 19px;
      }
      span {
        font-size: 24px;
      }
    }
    &.contact-email {
      margin-bottom: 10px;
      .icon.icon-email {
        width: 16px;
        height: 12px;
        margin: 6px 20px 0 0;
      }
    }
    &.contact-address {
      margin-bottom: 30px;
      .icon.icon-map {
        width: 16px;
        height: 21px;
        margin: 2px 20px 0 0;
      }
    }
  }

  .socials {
    display: flex;
    align-items: center;
    .item {
      &:not(:last-child) {
        margin-right: 10px;
      }
      a {
      }
      .icon {
        width: 28px;
        height: 28px;
      }
    }
  }

  .contact-logo {
    margin-bottom: 30px;
    img {
      width: 206px;
      height: 42px;
    }
  }
  .contact-copy {
    p {
      margin-bottom: 0;
    }
  }
}
